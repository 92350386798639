<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card pd_1">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0 no_print">
                <i class="fas fa-list"></i> ข้อมูลใบสั่งซื้อ
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body pd_1">
          <div class="pb-4 text-right no_print">
            <span
              ><router-link :to="'/order'" class="nav-link text-underline">
                กลับ</router-link
              ></span
            >
            <button
              type="button"
              class="btn btn-warning style_btn"
              @click="clickPrint()"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
          <div
            v-for="copy_type in copyTypes"
            :key="copy_type.key"
            class="card custom-card page-break"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mb-5 grid_3"></div>
                <div
                  class="col-md-4 p-4 text-center border mb-5 border-dark fs_18 grid_3"
                  style="font-size:25px;"
                >
                  {{ item.key_copy_type + ": " + getTypeCopyName }}
                </div>
                <div class="col-md-4 mb-5 grid_3"></div>
                <div class="col-lg-6 mb-4 flex_column grid_2">
                  <div class="mb-4">
                    <input
                      type="checkbox"
                      class="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    <h1>
                      {{ item.delivery_date }} - {{ item.delivery_time }}
                      {{ periodName }}
                    </h1>
                  </div>
                  <div class="mb-4 fs_20" style="font-size:25px;">
                    {{ item.supplier_name }}
                  </div>
                </div>
                <div class="col-lg-6 mb-4 flex_column grid_2 text-right">
                  <div class="mb-4 fs_20">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    <h1>เลขออเดอร์ : {{ item.order_code }}</h1>
                    <BarCode :value="item.order.code" :displayValue=false></BarCode>
                  </div>
                  <div class="mb-4 fs_30" style="font-size:25px;">
                    {{ item.store_name }}
                  </div>
                </div>
                <div
                  class="col-lg-3 align-self-center flex_column grid_3 border border-dark"
                  style="text-align-last: center; line-height: 5;"
                >
                  <div class="p-3">
                    <input
                      type="checkbox"
                      class="form-control"
                      style="width: 20px;
                            height: 20px;"
                    />
                  </div>
                  <div class="pb-2">
                    <img
                      :src="funcs.isImgEmpty(item.product_img)"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-6 flex_column grid_3 fs_25 border border-dark lh_4 pt-4"
                >
                  <div class="mb_55">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    &nbsp;
                    <span class="fs_20"
                      >รหัสสินค้า : {{ item.product_code }}</span
                    >
                  </div>
                  <br />
                  <div class="mb_55">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    &nbsp;
                    <span>ชนิดดอกไม้ : {{ item.categories }}</span>
                    <br />
                  </div>
                  <br />
                  <div class="mb_55">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    &nbsp;
                    <span>สี : {{ item.colors }}</span>
                    <br />
                  </div>
                  <br />
                  <div class="mb_55">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    &nbsp;
                    <span>จำนวนดอก : {{ item.qty }}</span>
                    <br />
                  </div>
                </div>
                <div class="col-lg-3 grid_3 fs_18 border border-dark lh_4 pt-4">
                  <div class="mb_55">
                    <div>ชื่อแมส</div>
                    <br />
                    <div>..............................................</div>
                  </div>
                  <div class="mb_55">
                    <div>เวลาออก</div>
                    <br />
                    <div>..............................................</div>
                  </div>
                  <div>
                    <div>เวลาส่งถึง/กลับ</div>
                    <br />
                    <div>..............................................</div>
                  </div>
                </div>
                <div
                  class="col-12 fs_18 border border-dark pt-4"
                  style="line-height: 1.7;"
                >
                  <div class="mb_55">
                    <input
                      type="checkbox"
                      clas="form-control"
                      style="width: 20px;
                            height: 20px;
                            vertical-align: middle;"
                    />
                    &nbsp;
                    <span
                      >ข้อความการ์ด :
                      <span>{{ item.card_msg }}</span>
                    </span>
                  </div>
                  <br />
                  <br />
                </div>
                <div class="col-lg-4 fs_18 border border-dark pt-4 grid_3">
                  <label class="form-label" style="font-weight: bold;"
                    >ข้อมูลผู้ส่ง</label
                  >
                </div>
                <div class="col-lg-4 fs_18 border border-dark pt-4 grid_3">
                  <label class="form-label" style="font-weight: bold;"
                    >ข้อมูลผู้รับ</label
                  >
                </div>
                <div class="col-lg-4 fs_18 border border-dark pt-4 grid_3">
                  <label class="form-label" style="font-weight: bold;"
                    >ข้อมูลผู้รับสำรอง</label
                  >
                </div>
                <div
                  class="col-xl-4 col-lg-4 flex_column grid_3 fs_18 border border-dark"
                >
                  <div class="my-4">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label" style="font-weight: bold;"
                          >ชื่อ :</label
                        >
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">{{ item.sender_name }}</label>
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label" style="font-weight: bold;"
                          >เบอร์โทร :</label
                        >
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">{{
                          item.sender_phone
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-lg-4 flex_column grid_3 fs_18 border border-dark"
                >
                  <div class="my-4">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >ชื่อ :
                        </label>
                        <label class="form-label">{{
                          item.addressee_name
                        }}</label>
                      </div>
                      <div class="col-12 mb-3"></div>
                      <div class="col-12 mb-3">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >เบอร์โทร :
                        </label>
                        <label class="form-label">{{
                          item.addressee_phone
                        }}</label>
                      </div>
                      <div class="col-12 mb-3"></div>
                      <div class="col-12 mb-3">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >ที่อยู่จัดส่ง :
                        </label>
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">{{
                          item.delivery_address
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-lg-4 flex_column grid_3 fs_18 border border-dark"
                >
                  <div class="my-4">
                    <div class="row">
                      <div class="col-12 mb-3 grid_2">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >ชื่อ :
                        </label>
                        <label class="form-label">{{
                          item.receiver_name
                        }}</label>
                      </div>
                      <div class="col-12 mb-3 grid_2"></div>
                      <div class="col-12 mb-3 grid_2">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >เบอร์โทร :
                        </label>
                        <label class="form-label">{{
                          item.receiver_phone
                        }}</label>
                      </div>
                      <div class="col-12 mb-3 grid_2"></div>
                      <div class="col-12 mb-3">
                        <label
                          class="form-label mr-2"
                          style="font-weight: bold;"
                          >ที่อยู่จัดส่ง :</label
                        >
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">{{
                          item.receiver_address
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 border border-dark"></div>
                <div class="col-md-4 p-2 text-center border border-dark">
                  <h1 class="text-dark">
                    เขตจัดส่ง : {{ item.district_name }}
                  </h1>
                </div>
                <div class="col-md-4 border border-dark"></div>
                <div class="col-12 border p-2 border border-dark">
                  <h1>
                    <label class="form-label" style="font-weight: bold;"
                      >ข้อเน้นย้ำ :</label
                    >
                    {{ item.detail }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as scripts from "../../../util/scripts";
import moment from "moment";
import StringUtils from "../../../util/StringUtils";
import DateUtils from "../../../util/dateUtils";
import Constants from "../../../util/constants";
import BarCode from '../component/barcode.vue';
export default {
  components: {
    BarCode,
  },
  name: "order-view-page-bill-order",
  data() {
    return {
      noImageData: Constants.noImageData,
      permission: {
        appSlug: "order",
        actionData: {}
      },
      StringUtils: StringUtils,
      item: {
        customer: null,
        store: null,
        sale: null,
        products: [],
        store_id: 1,
        store_name: "Love You Flower",
        delivery_date: "14/02/2022",
        delivery_period: 1,
        order_code: "O-0500055",
        // products: [{
        product_code: "V227",
        product_img: "",
        product_qty: 5,
        // product_code_more: '',
        // product_name_more: '',
        remark: "",
        card_msg:
          "วันธรรมดา หายใจออกมาเป็นไอ วันวาเลนไทน์หายใจออกมาเป็นเธอ รักนะ จากคนที่คุณก็รู้ว่าใคร",
        // }],
        messager_name: "",
        time_out: "",
        time_back: "",
        sender_name: "Carra",
        sender_phone: "0123456789",
        addressee_name: "ณัฐกาม่อน",
        addressee_phone: "0987654321",
        delivery_time: "08:00",
        delivery_address:
          "บริษัท แอพบิท สตูดิโอ จำกัด เลขที่ 114/1 ซอยปราโมทย์2 ถนนมเหสักข์แขวงสุริยวงศ์เขตบางรัก กรุงเทพฯ 10500",
        key_copy_type: "A",
        supplier_name: "Vday ร้านดอกไม",
        categories: "ดอกกุหลาบ",
        colors: "สีชมพู",
        qty: "5 ดอก",
        city_name: "กทม.",
        district_name: "ราชเทวี",
        subdistrict_name: "ทุ่งพญาไท",
        detail:
          "อย่าให้เขานั้นรู้ว่าฉันเคยเป็นใคร อย่าให้เขาสงสัยเรื่องที่ผ่านมา",
        receiver_name: "คุณเบนซ",
        receiver_phone: "0876543210",
        receiver_address:
          "บริษัท แอพบิท สตูดิโอ จำกัด เลขที่ 114/1 ซอยปราโมทย์2 ถนนมเหสักข์แขวงสุริยวงศ์เขตบางรัก กรุงเทพฯ 10500"
      },
      periods: [
        {
          id: 1,
          text: "08.00-13.00"
        },
        {
          id: 2,
          text: "14.00-19.00"
        }
      ],
      funcs: scripts.funcs,
      copyTypes: [
        {
          key: "A",
          name: "สำหรับคนไปร้าน"
        },
        {
          key: "S",
          name: "สำหรับซัพพลายเออร์"
        },
        {
          key: "M",
          name: "สำหรับแมสเซนเจอร์ "
        }
      ]
    };
  },
  computed: {
    periodName() {
      let time = this.item.delivery_time;
      if (time == "08:00") {
        return "รอบเช้า";
      } else if (time == "13:00") {
        return "รอบบ่าย";
      } else if (time == "18:00") {
        return "รอบเย็น";
      } else if (time == "") {
        return "";
      } else {
        // เวลาอื่นๆ
        return "รอบพิเศษ";
      }
    },
    getTypeCopyName() {
      let obj = {
        name: ""
      };
      obj = this.copyTypes.find(row => {
        if (row.key == this.item.key_copy_type) {
          return row;
        }
      });
      return obj.name;
    }
  },
  methods: {
    disabledTimeOut(date) {
      if (this.item.time_back) {
        return date > this.item.time_back;
      }
      return false;
    },
    disabledTimeBack(date) {
      if (this.item.time_out) {
        return date < this.item.time_out;
      }
      return false;
    },
    async clickPrint() {
      await this.HttpServices.putData(
        `/ab_order/updatePrintStatus/${this.id}`,
        { status: 1 }
      );

      window.print();
    },
    getLabelPeriod(period) {
      let result = "";
      for (let i in this.periods) {
        if (period == this.periods[i].id) {
          result = this.periods[i].text;
        }
      }
      return result;
    },
    getFormatTime(date) {
      let result = "";
      if (date) {
        result = moment(date).format("HH:mm");
      }
      return result;
    }
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    this.id = await this.SessionStorageUtils.getSession("page-order");
    const result = await this.HttpServices.getData(`/ab_order/${this.id}`);
    if (result && result.status.code == "200") {
      this.item = result.data;
      this.service = this.item.service;
      if (this.item.expected_delivery_date) {
        this.item.expected_delivery_date = DateUtils.dateFormat(
          this.item.expected_delivery_date,
          "DD/MM/YYYY"
        );
      }
      if (this.item.products) {
        this.products = JSON.parse(JSON.stringify(this.item.products));
        for (const v of this.products) {
          if (v.logoImg) {
            v.logoImg = `${v.logoImg}`;
          }
          if (v.imageDisplays && v.imageDisplays.length > 0) {
            const images = [];
            for (const vv of v.imageDisplays) {
              images.push(`${vv}`);
            }
            v.imageDisplays = images;
            v.imageFiles = [];

            for (const vv of v.products) {
              if (vv.logoImg) {
                vv.logoImg = `${vv.logoImg}`;
              }
              if (vv.imageDisplays && vv.imageDisplays.length > 0) {
                const images = [];
                for (const ss of v.imageDisplays) {
                  images.push(`${ss}`);
                }
                vv.imageDisplays = images;
                vv.imageFiles = [];
              }
            }
          }
        }
      }
    } else {
      this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูลการสั่งซื้อ`, () => {
        this.$router.push("/order");
      });
    }
  }
};
</script>
<style scoped>
.card .table.bill_order td {
  padding-left: 4px;
  padding-right: 4px;
  white-space: inherit;
}
</style>
